
const customerDbhSettings = {
    '000001' : {'bucketSize': 0.03},
    '000002' : {'bucketSize': 0.03},
    '000003' : {'bucketSize': 0.03},
    '000004' : {'bucketSize': 0.03},
    '000005' : {'bucketSize': 0.03},
    '000006' : {'bucketSize': 0.03},
    '000007' : {'bucketSize': 0.03},
    '000008' : {'bucketSize': 0.03},
    '000009' : {'bucketSize': 0.03},    
    '000010' : {'bucketSize': 0.03},    
    '000011' : {'bucketSize': 0.03},
    '000012' : {'bucketSize': 0.03},
    '000013' : {'bucketSize': 0.03},    
    '000014' : {'bucketSize': 0.03},    
    '000015' : {'bucketSize': 0.03},        
    '000016' : {'bucketSize': 0.03},        
    '000017' : {'bucketSize': 0.03},        
    '000018' : {'bucketSize': 0.03},        
    '000019' : {'bucketSize': 0.03},        
    '111111' : {'bucketSize': 0.03},
    '101010' : {'bucketSize': 0.03},
}
const customerVolumeSettings = {
    '000001' : {'bucketSize': 0.05},
    '000002' : {'bucketSize': 0.05},
    '000003' : {'bucketSize': 0.05},
    '000004' : {'bucketSize': 0.05},
    '000005' : {'bucketSize': 0.05},
    '000006' : {'bucketSize': 0.05},
    '000007' : {'bucketSize': 0.05},
    '000008' : {'bucketSize': 0.05},
    '000009' : {'bucketSize': 0.05},    
    '000010' : {'bucketSize': 0.05},    
    '000011' : {'bucketSize': 0.05},
    '000012' : {'bucketSize': 0.05},
    '000013' : {'bucketSize': 0.05},    
    '000014' : {'bucketSize': 0.05},    
    '000015' : {'bucketSize': 0.05},        
    '000016' : {'bucketSize': 0.05},        
    '000017' : {'bucketSize': 0.05},        
    '000018' : {'bucketSize': 0.05},        
    '000019' : {'bucketSize': 0.05},        
    '111111' : {'bucketSize': 0.05},
    '101010' : {'bucketSize': 0.05},
}

const customerHeightSettings = {
    '000001' : {'bucketSize': 0.5},
    '000002' : {'bucketSize': 0.5},
    '000003' : {'bucketSize': 0.5},
    '000004' : {'bucketSize': 0.5},
    '000005' : {'bucketSize': 0.5},
    '000006' : {'bucketSize': 0.5},
    '000007' : {'bucketSize': 0.5},
    '000008' : {'bucketSize': 0.5},
    '000009' : {'bucketSize': 0.5},    
    '000010' : {'bucketSize': 0.5},    
    '000011' : {'bucketSize': 0.5},
    '000012' : {'bucketSize': 0.5},
    '000013' : {'bucketSize': 0.5},    
    '000014' : {'bucketSize': 0.5},    
    '000015' : {'bucketSize': 0.5},        
    '000016' : {'bucketSize': 0.5},        
    '000017' : {'bucketSize': 0.5},        
    '000018' : {'bucketSize': 0.5},        
    '000019' : {'bucketSize': 0.5},        
    '111111' : {'bucketSize': 0.5},
    '101010' : {'bucketSize': 0.5},
}

export {customerDbhSettings, customerVolumeSettings, customerHeightSettings}