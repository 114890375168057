import * as d3 from "d3";

export const Access = Object.freeze({
  ADMIN:{number: 0, string: "admin"},
  DOWNLOAD_PCD: {number: 1, string: "pcdDownload"},
  DOWNLOAD_FLIGHT_PATH:{number: 2, string: "flightPathDownload"},
  MERGE_STAND_DATA: {number: 3, string: "mergeStandData"},
  ADD_TO_STAND: {number: 4, string: "addToStand"},
})
export const getAccessString = (number) => {
  const accessEntry = Object.values(Access).find(access => access.number === number);
  // console.log(accessEntry)
  return accessEntry ? accessEntry.string : "unknown";
};

export const hasAccess = (customer, requestAccess=0) => {
  if(customer.access){
    if(customer.access.includes(0)){
      return true
    }
    return customer.access.includes(requestAccess)
  }
}
export default class Helper {

  static range(start, stop, step) {
    if (typeof stop == 'undefined') {
        // one param defined
        stop = start;
        start = 0;
    }

    if (typeof step == 'undefined') {
        step = 1;
    }

    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return [];
    }

    var result = [];
    for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i);
    }

    return result;
  };

  static getStatus(status) {
      if(status === 'SlamInProgress') {
        return 'Creating map (1/4)'
      } 
      else if(status === 'Segmenting'){
        return 'Finding trees (2/4)'
      }
      else if(status === 'ExtractingData'){
        return 'Measuring found trees (3/4)'
      }
      // Not implemented yet
      else if(status === "WaitingForSegmenting"){
        return 'Waiting for pre processing (2/4)'
      }
      // *****
      else if(status === 'Finalizing' || status ==='FailedNoTreesFound'){
      return 'Finalizing 3D view (4/4)'
      }
      else if(String(status).includes('Waiting')) {
        return 'Wating to start (0/4)'
      }
      else if(status === 'notStartedCanceled'){
        return 'Not started - canceled.'
      }
      else if(status === 'notStarted'){
        return 'Not started'
      }
      else if(String(status).includes('Finished')){
        return ''
      }
      else if(String(status).includes('Failed')){
        return String(status)
      }
      return status
  }

}