import React, {useEffect, useState , useMemo} from 'react'
import { ProgressBar, Button, Form, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import {useDropzone} from 'react-dropzone'
import { styles } from './styles';
import axios from "axios";
import useToken from '../App/useToken';
import { hasAccess } from '../Helper/Helper';

const chunkSize = 1048576 * 5;//its 5MB, increase the number measure in mb


export default function DropZone(props) {
  
  const [showProgress, setShowProgress] = useState(false)
  const [counter, setCounter] = useState(0)
  const updateCounter = ( currentCounter) =>{
    setCounter(currentCounter)
  }
  const [fileToBeUpload, setFileToBeUpload] = useState({})
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
  const [progress, setProgress] = useState(0)
  const [fileGuid, setFileGuid] = useState("")
  const [fileSize, setFileSize] = useState(0)
  const [uploadFile, setUploadFile] = useState(false)
  const [chunkCount, setChunkCount] = useState(0)
  const [customerToUpload, setCustomerToUpload] = useState()
  const [errorMsg, setErrorMsg] = useState()
  const [DOF, setDOF] = useState('Unknown')
  const progressInstance = <ProgressBar animated now={progress} label={`${progress.toFixed(2)}%`} />;
  const {token, getCustomer} = useToken()
  const [customer, setCustomer] = useState(getCustomer())
  const [customersOptions, setCustomersOptions] = useState([])

  useEffect(() => {
    var formData = new FormData();
    formData.append('token', token);
    var config = {
        method: 'post',
        url: "api/get_customers",
        data: formData
    };
    axios(config).then(response => {
        // console.log( response.data)
        var customersList = [<option>Select customer for upload</option>] 
        response.data.customers.forEach(element => {
           customersList.push(<option value={element.customerId}>{element.name}</option>) 
        });
        setCustomersOptions(customersList)
    }).catch(error => {
        console.log('error', error)
    })
  },[])

  const getFileContext = (e) => {
    resetChunkProperties();
    const _file = e[0];
    if(_file){
      setFileSize(_file.size)
      const _totalCount = _file.size % chunkSize === 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1;
      console.log(_totalCount) // Total count of chunks will have been upload to finish the file
      setChunkCount(_totalCount)

      setFileToBeUpload(_file)
      const _fileID = _file.name;
      setFileGuid(_fileID)
      setDOF(getDate())
    }
  }
  useEffect(() => {
    if(fileSize > 1 && uploadFile){
      fileUpload(counter)
    }

  },[uploadFile, progress])
  const fileUpload = (count) => {
    updateCounter(count + 1);
    if (count <= chunkCount) {
      console.log("fileUpload, chunk " + count)
      
      var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
      if(fileToBeUpload.size <= chunkSize){
        setEndOfTheChunk(fileToBeUpload.size)
        chunk = fileToBeUpload
      }
      uploadChunk(chunk)   
    }
  }
 
  const uploadChunk = (chunk) => {
    console.log("uploading chunk " + counter)
    var formData = new FormData();
    formData.append('token', token)
    formData.append('files', chunk)
    formData.append('filename', fileGuid);
    formData.append('chunkcount', counter);
    formData.append('totalchunkcount', chunkCount);
    formData.append('totalfilesize', fileSize);
    formData.append('chunkoffset', beginingOfTheChunk);

    formData.append('droneId', 'Unknown');
    formData.append('DOF', DOF);
    if(customerToUpload){
      formData.append('customerId', customerToUpload);
    } else {
      formData.append('customerId', customer);
    }

    var config = {
      method: 'post',
      url: "file-handler/upload",
      data : formData
    };
    axios(config).then(response => {
        console.log(response)
        setBeginingOfTheChunk(endOfTheChunk);
        setEndOfTheChunk(endOfTheChunk + chunkSize);
        if (counter === chunkCount) {
          console.log('Process is complete, counter', counter)

          const finished = uploadCompleted();
          if (finished) {
            console.log("Upload finished successfully")
          }

        } else {
          const percentage = (counter / chunkCount) * 100;
          setProgress(percentage);
        }

    }).catch(error => {
      setErrorMsg(error?.response?.data?.error)
      console.log('error', error)
    })
  }
  function pad2(n) { return n < 10 ? '0' + n : n }
  function getDate() {
    var date = new Date();
    return `${date.getFullYear().toString()}-${pad2(date.getMonth() + 1)}-${pad2( date.getDate())}-${pad2( date.getHours() )}-${pad2( date.getMinutes() )}-${pad2( date.getSeconds() )}`
  }

  const uploadCompleted = () => {
    if(!token)
      return "not atuhorized"
    var formData = new FormData();
    formData.append('filename', fileGuid);
    formData.append('droneId', 'Unknown');
    formData.append('DOF', DOF);
    formData.append('token', token)
    if(customerToUpload){
      formData.append('customerId', customerToUpload);
    } else {
      formData.append('customerId', customer);
    }
    
    formData.append('alias', '');
    var config = {
      method: 'post',
      url: "file-handler/uploadComplete",
      data : formData
    };
    axios(config).then(response => 
      {
        const status = response.status;
        if (status === 200) {
          setProgress(100);
          
          setUploadFile(false)
          setFileSize(0)
          return true
      }
    }).catch(error => {
      console.log('error', error)
    })
  }

  const resetChunkProperties = () => {
    setShowProgress(true)
    setProgress(0)
    setCounter(1)
    setBeginingOfTheChunk(0)
    setEndOfTheChunk(chunkSize)
    setUploadFile(false)
    setFileSize(0)
    setErrorMsg()
    setDOF('Unknown')
  }
  const allowedFiles = hasAccess(customer) ? {
    'application/octet-stream' : ['.bag', '.las'],
    'application/vnd.nokia.pcd+xml': ['.pcd'],
    'image/x-photo-cd' : ['.pcd'],
    'application/vnd.las': ['.las'],
    'application/unknown': ['.las', '.bag'],
    'application/vnd.laszip': ['.laz'],
    'application/vnd.google-earth.kml+xml':['.kml'],
    'application/vnd.google-earth.kmz': ['.kmz']} : 
    {
      'application/vnd.google-earth.kml+xml': ['.kml'], 
      'application/vnd.google-earth.kmz': ['.kmz']
    }
  const [fileAccepted, setFileAccepted] = useState(true)
  const {
      acceptedFiles,
      open,
      fileRejections,
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject } = useDropzone({
          maxFiles: 1,
          accept: allowedFiles,
          onDropAccepted: e => {
            // console.log(e)
            getFileContext(e)
          },
          // onDrop: acceptedFiles => {
          //     setFileAccepted(true)
          //     getFileContext(acceptedFiles)},
          onDropRejected: e => {
            // console.log(e)
            resetChunkProperties()
            }  
      });

    const style = useMemo(() => ({
            ...styles.baseStyle,
            ...(isFocused ? styles.focusedStyle : {}),
            ...(isDragAccept ? styles.acceptStyle : {}),
            ...(isDragReject ? styles.rejectStyle : {})
          }), [
            isFocused,
            isDragReject,
            isDragAccept
          ]);  

    const files = acceptedFiles.map(file => {
       return ( 
        <>
        <h4>{'File to upload'}</h4>
        
        <ul>
            <li key={file.path}>
                {file.path} - {file.size} bytes
            </li>
            <li key={file.lastModified}>
                last modified: {new Date(file.lastModified).toLocaleDateString()}
            </li>
      </ul>
      </>)
    
    });
    const fileRejectionItems = fileRejections.map(({ file, errors  }) => { 
      return (
        <>
        <h4>{'File not allowed'}</h4>
        <li key={file.path}>
             {file.path} - {file.size} bytes
             <ul>
               {errors.map(e => <li key={e.code}>{hasAccess(customer) ? 'Accepted file types: .bag, .las, .laz, .pcd, .kml, .kmz':'Accepted file types: .kml, .kmz' }</li>)}
            </ul>
   
        </li>
        </>
      ) 
     });
    const closeDropZone = () => {
      // console.log("clicked")
      props.onHide(false)
      resetChunkProperties()
      setFileToBeUpload({})
    }
    
    return (  
            <Modal
                show={props.show}
                onHide={() => props.onHide(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="container">
                        <div {...getRootProps({style})} onClick={(e) => {console.log(e); open()}}>
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop kml, kmz files here, or click to select them</p>
                            <p>({hasAccess(customer) ? 'Must be of file type: .bag, .las, .laz, .pcd, .kml, .kmz':'Must be of file type: .kml, .kmz' })</p>
                        </div>
                    </div>
                    <aside>
                      <ul>{files}</ul>
                      <ul>{fileRejectionItems}</ul>
                      <p style={{color: 'orangered'}}>{errorMsg}</p>
                    </aside>
                    <Form.Group style={{ display: showProgress ? "block" : "none" }}>
                        {progressInstance}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {hasAccess(customer) && <Form.Select aria-label="Default select example"
                      onChange={(e) => {
                      console.log(e.currentTarget.value)
                      setCustomerToUpload(e.currentTarget.value)}}
                      disabled={uploadFile}>
                          {customersOptions}
                    </Form.Select>}
                    <Button onClick={() => setUploadFile(true)} hidden={fileSize<1 || errorMsg} disabled={uploadFile}>{progress === 100 ? "Finished!"  : uploadFile ? "Uploading..." : "Upload"}</Button>
                    <Button onClick={() => closeDropZone()}>{progress === 100 ? "Done" : fileSize > 1 ? "Cancel" : "Close" }</Button>
                </Modal.Footer>
            </Modal>
    )
  }

DropZone.propTypes = {
    onHide: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired
}