import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import axios from "axios";
import useToken from '../App/useToken';
import { saveAs } from 'file-saver';

export default function DownloadDialogPopup(props) {
  const {token} = useToken()
  const name = props.itemToDownload ? props.itemToDownload.name : ''
  const [progress, setProgress] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState(null);
  const [cancelController, setCancelController] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  // const item = props.itemToDownload ? props.itemToDownload : null
  // const path = item ? 
  // 'https://portal.deepforestry.com/potree/app-mounted-file-storage/customers/'+item.customerId+'/'+item.droneId+'/'+item.DOF+'/pcd_files/'+item.name+'.pcd' :
  // ''
  useEffect(()=>{
    setErrorMessage('');
  },[props.downloadDialogShow])

  const downloadFile = async (item) => {
    if(item){
      const formData = new FormData();
      let url = "file-handler/downloadFile"
      let responseType = 'blob'
      console.log()
      if(props.ctx?.showShapeData?.current){
        formData.append('AID', item.AID);
        formData.append('customerId', item.customerId);
        url = "file-handler/downloadStandFiles"
        responseType = 'stream'
      } else {
        formData.append('name', item.name);
        formData.append('DOF', item.DOF);
        formData.append('droneId', item.droneId);
        formData.append('customerId', item.customerId);
        formData.append('id', item._id);
        formData.append('token', token);
      }
      const controller = new AbortController();
      setCancelController(controller);
      const config = {
        signal: controller.signal,
        method: 'post',
        url: url,
        data: formData,
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          console.log(progressEvent)
          const { loaded, total } = progressEvent;
          setProgress(Math.round((loaded / total) * 100));
          
          const elapsedTime = (Date.now() - startTime) / 1000; // Time in seconds
          const downloadSpeed = loaded / elapsedTime; // Bytes per second
          const timeRemaining = (total - loaded) / downloadSpeed; // Time in seconds
          setEstimatedTime(timeRemaining);
        }
      }
      const startTime = Date.now();
      
      if(props.ctx.showShapeData?.current){
        axios(config).then(async response => {
          const filename = item.alias+".zip"
          const reader = response.data.getReader();
          const stream = new ReadableStream({
            start(controller) {
              function push() {
                reader.read().then(({ done, value }) => {
                  if (done) {
                    controller.close();
                    return;
                  }
                  controller.enqueue(value);
                  push();
                });
              }
              push();
            }
          });
    
          const blob = await new Response(stream).blob();
          saveAs(blob, filename);
          setProgress(0);
          setEstimatedTime(null);
          setErrorMessage(''); // Clear any previous error message
        })
        .catch(error => {
          if (error.name === 'AbortError') {
            console.log('Download canceled');
          } else if (error.response && error.response.status === 404) {
            setErrorMessage('File not found');
          } else {
            console.error('There was an error!', error);
            setErrorMessage('An error occurred while downloading the files');
          }
          setProgress(0);
          setEstimatedTime(null);
        })
        .finally(() => {
          setCancelController(null);
        });
      } else {
    
      
        axios(config).then(response => {
          
          let filename = item.name+".pcd"
          
          const blob = new Blob([response.data], { type: 'application/octet-stream' });
          saveAs(blob, filename);
          setProgress(0);
          setEstimatedTime(null);
          setErrorMessage('');
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            console.log('Download canceled');
          } else if (error.response && error.response.status === 404) {
            setErrorMessage('File not found');
          } else {
            setErrorMessage('An error occurred while downloading the file')
            console.error('There was an error!', error);
          }
        })
        .finally(() => {
          setCancelController(null);
        });
      }
    }
  }
  const cancelDownload = () => {
    if (cancelController) {
      cancelController.abort('Download canceled by user');
      setProgress(0);
      setEstimatedTime(null);
      console.log("canceled download")
    }
    props.setDownloadDialogShow(false)
  };
  return (
    <Modal
      show={props.downloadDialogShow}
      onHide={() => props.setDownloadDialogShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div style={{backgroundColor: "#212529", color: "#ffffff"}}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          
          Download item {props.itemToDownload ? props.itemToDownload.name : ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {progress > 0 && (
        <div>
          <p>Download progress: {progress}%</p>
          <p>Estimated time remaining: {Math.round(estimatedTime)} seconds</p>
        </div>
      )}
      {errorMessage && (
        <div style={{ color: 'red' }}>
          <p>{errorMessage}</p>
        </div>
      )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={cancelDownload} >Cancel</Button>
          <Button
            disabled={cancelController}
            onClick={() => { 
              downloadFile(props.itemToDownload)
            }}>
              Download
          </Button>
      </Modal.Footer>
      </div>
    </Modal>
  );
}

DownloadDialogPopup.propTypes = {
  itemToDownload: PropTypes.object.isRequired,
  downloadDialogShow: PropTypes.bool.isRequired,
  setDownloadDialogShow: PropTypes.func.isRequired,
  ctx: PropTypes.object.isRequired,
}