import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, Button } from 'react-bootstrap'
import { CSVLink } from "react-csv";
import { saveAs } from 'file-saver';
// import useToken from '../App/useToken';
export default function ForestDetailsSummary(props) {
    // const {customer} = useToken()
    const handleDownloadImage = async () => {
        if(props.chartWrappers['DbhHist'] !== null) {
            const image = props.chartWrappers['DbhHist'].getChart().getImageURI();
            saveAs(image, props.name+'DbhHist.png')
        }
        if(props.chartWrappers['VolumeHist'] !== null) {
            const image = props.chartWrappers['DbhHist'].getChart().getImageURI();
            saveAs(image, props.name+'VolumeHist.png')
        }
        if(props.chartWrappers['HeightHist'] !== null) {
            const image = props.chartWrappers['DbhHist'].getChart().getImageURI();
            saveAs(image, props.name+'HeightHist.png')
        }
      };
    const headers = [
        { label: "Alias", key: "Alias" },
        { label: "ID", key: "ID" },
        { label: "x-coordinate", key: "x" },
        { label: "y-coordinate", key: "y" },
        { label: "DBH", key: "DBH" },
        { label: "Volume", key: "volume" },
        { label: "Lon", key: "lon" },
        { label: "Lat", key: "lat" },
        { label: "Elev", key: "elev" },
        { label: "Raw height", key: "heightRaw" },
        { label: "Type", key: "type" },
        { label: "method", key: "method" },
      ];
    useEffect(()=>{
        if(props.ctx.showShapeData?.current){
            console.log(props.totStandData)
        }
    }, [props.ctx.showShapeData?.current])
    const area = props.area !== 0.000 ? props.area : '' 
    const treeDensity = props.area !== 0.000 ? Number(props.totalTrees/props.area).toFixed(3) : '' 
    const basalArea = props.area !== 0.000 ? Number(props.basalArea/props.area).toFixed(3) : '' 
    return (
        <Card
            bg={'dark'}
            key={'ForestDetailsSummary'}
            text={'white'}
            style={{ width: props.style, height: 'fit-content', margin: '1em', marginBottom: "1em!important", border: "3px outset #0f5132" }}
            className="mb-3"
        >
            <Card.Header style={{fontSize: "1em", fontWeight: "bold", display: "flex", lineBreak: "anywhere" }}>
                {props.name} 
                {!props.ctx.showShapeData?.current ? 
                    <Button style={{border: "2px outset #00d098", marginLeft:"1em"}} size='sm' variant="outline-secondary" onClick={() => props.setViewTable()}>
                        Toggle Hist/Table
                    </Button> : null
                }
            </Card.Header>
            <Card.Body>
            <Card.Title> Summary </Card.Title>
                <ul style={{paddingBottom: '10px'}}>
                    <Card.Text>
                        Total Number of Trees: {props.ctx.showShapeData?.current ? props.totStandData.possibleTrees : props.totalTrees} 
                    </Card.Text>
                    <Card.Text>
                        Area (hectare): {props.ctx.showShapeData?.current ? props.totStandData.area : area}
                    </Card.Text>
                    <Card.Text>
                        Tree Density (trees per hectare): {props.ctx.showShapeData?.current ? Number(props.totStandData.possibleTrees/props.totStandData.area).toFixed(3) : treeDensity}
                    </Card.Text>
                    <Card.Text>
                        Basal Area Density (m²/ha): {props.ctx.showShapeData?.current ? Number(props.totStandData.basalArea/props.totStandData.area).toFixed(3) :basalArea}
                    </Card.Text>
                    <Card.Text>
                        Avarage Volume: {props.ctx.showShapeData?.current ? Number(props.totStandData.sumVol/props.totStandData.noVol).toFixed(3) : props.avgVol} 
                    </Card.Text>
                    <Card.Text>
                        Avarage DBH: {props.ctx.showShapeData?.current ? Number(props.totStandData.sumDBH/props.totStandData.noDBH).toFixed(3) : props.avgDBH} 
                    </Card.Text>
                    <Card.Text>
                        Avarage Height: {props.ctx.showShapeData?.current ? Number(props.totStandData.sumHeight/props.totStandData.noHeight).toFixed(3) : props.avgHeight} 
                    </Card.Text>
                    <Card.Text>
                        Trees Discarded (DBH cutoff 0.7m): {props.ctx.showShapeData?.current ? props.totStandData.discardedTrees : props.discardedTrees} 
                    </Card.Text>
                </ul>
                <CSVLink data={props.ctx.showShapeData?.current ? props.totStandData.full : props.full} headers={headers} filename={props.ctx.showShapeData?.current ? props.ctx.selectedShapeData.current.name : props.name}>
                    <Button variant="outline-secondary">
                        Export CSV
                    </Button> 
                </CSVLink>
                
                <Button onClick={props.downloadGeojson} variant="outline-secondary">
                    Export Geojson...
                </Button> 
                
                {/* {props.viewTable && customer.customerId === '101010' ?  */}
                {/* // <Button variant="outline-secondary" onClick={props.toggleEditTable()} disabled={props.chartWrappers === {'DbhHist': null, 'VolumeHist': null, 'HeightHist': null }}>{props.editTable ? 'Stop editing' : 'Edit table'}</Button> : */}
                {/* <Button variant="outline-secondary" onClick={handleDownloadImage} disabled={props.chartWrappers === {'DbhHist': null, 'VolumeHist': null, 'HeightHist': null }}>Download histograms</Button> */}
            </Card.Body>
        </Card>
    )
}


ForestDetailsSummary.propTypes = {
    toggleEditTable: PropTypes.func.isRequired,
    editTable: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    avgDBH: PropTypes.number.isRequired,
    avgVolume: PropTypes.number.isRequired,
    avgHeight: PropTypes.number.isRequired,
    totalTrees: PropTypes.number.isRequired,
    basalArea: PropTypes.number.isRequired,
    area: PropTypes.number.isRequired,
    style: PropTypes.number.isRequired,
    viewTable: PropTypes.bool.isRequired,
    setViewTable: PropTypes.func.isRequired,
    discardedTrees: PropTypes.number.isRequired,
    full: PropTypes.array.isRequired,
    chartWrappers: PropTypes.object.isRequired,
    ctx: PropTypes.object.isRequired,
    totStandData: PropTypes.object.isRequired,
    downloadGeojson: PropTypes.func.isRequired,
  }